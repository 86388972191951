export interface Breadcrumb {
    label: string;
    callback: () => void;
};

export default function useSubHeaderState() {
    const breadcrumbs = useState<any>('sub_header_breadbrumbs', () => []);

    return {
        breadcrumbs,
    };
}
